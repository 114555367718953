.form-section {
  display: flex;
  gap: 10px;
  flex-basis: 100%;
  flex-wrap: wrap;
}
@media (max-width: 900px) {
  .form {
    height: calc(100% - 76px);
  }
}

.block-element {
  flex: 1;
  flex-basis: 100%;
}
